<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">Relay slots for {{race.name}}</v-card-title>

      <v-card-text>      
        Customize the relay slots for this race.

        <v-alert type="info" class="mt-8">This is a BETA feature. Feedback is welcome through our contact form :)</v-alert>
      </v-card-text>

      <v-card-text>      
        <strong>Note:</strong> do not make changes to the slot configuration after participants have been invited!
      </v-card-text>

      <v-card-text v-if="race.team == 'RELAY_TIME_SLOTS'">      
        <strong>Note:</strong> all times are in the <strong>{{event.timezone}}</strong> time zone. Participants will see the times in their local time zone during slot assignment.
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="slots"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Relay Slots</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <v-text-field v-model="item.name" hide-details />
        </template>
        <template v-slot:item.type="{ item }">
          <v-select
            :items="$helpers.prefixSelectListWith(siteData.activities, {type: null, text:'Any sport'})"
            v-model="item.type" 
            item-value="type"
            item-text="text"
            placeholder="- Any -"
            style="width:100px"
            hide-details
          />
        </template>
        <template v-slot:item.min_distance="{ item }">
          <DistanceTextArea v-model="item.min_distance" :unit="event.unit" hideDetails  />
        </template>
        <template v-slot:item.distance="{ item }">
          <DistanceTextArea v-model="item.distance" :unit="event.unit" hideDetails  />
        </template>
        <template v-slot:item.max_distance="{ item }">
          <DistanceTextArea v-model="item.max_distance" :unit="event.unit" hideDetails  />
        </template>
        <template v-slot:item.start="{ item }">
          <DateAndTimeWithTimeZonePicker v-model="item.start" :timeZone="event.timezone" hideDetails :label="null" :timeLabel="null" forceShowTime  />
        </template>
        <template v-slot:item.end="{ item }">
          <DateAndTimeWithTimeZonePicker v-model="item.end" :timeZone="event.timezone" hideDetails :label="null" :timeLabel="null" forceShowTime  />
        </template>
        <template v-slot:footer>
          <v-btn text @click="addSlot"><v-icon small color="gray" class="mr-2">fa-plus-circle</v-icon> Add a slot</v-btn>
          <span class="text-muted">(To delete a slot: clear the display name and press Save)</span>
        </template>
      </v-data-table>
      <v-card-text>
        <v-btn color="primary" large @click="save">Save Slots</v-btn>
        <v-btn color="primary" text @click="reset">Delete all Slots</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import draggable from 'vuedraggable'
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";

export default {
  name: "RelaySlots",
  components: {
    Header,
    draggable,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      slots: [],
      siteData: siteData,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.slots = (await eventManagerService.getRelaySlots(id, raceId)).data.data || [];
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async addSlot() {
      this.slots.push({});
    },

    async reset() {
      if (confirm('Are you sure you want to delete all relay slots?')){
        await eventManagerService.putRelaySlots(this.event.id, this.race.id, []);
        await this.getProfile();
      }
    },

    async save() {
      const slots = this.slots;
      var count = 1;
      for (const slot of slots) {
        if (!slot.id){
          slot.id = count + '-' + this.$helpers.randomId(); // assign new id to all new rows
        }
        count++;
      }

      console.log('Saving', slots);
      const response = (await eventManagerService.putRelaySlots(this.event.id, this.race.id, slots)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved relay slots.');

      await this.getProfile();
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Relay Slots', disabled: true },
      ];
    },

    headers() {
      return !this.event || !this.race ? [] : 
      [
        { text: 'Display Name', value: 'name', sortable: false },
        { text: 'Sport', value: 'type', sortable: false },
        this.race.team == 'RELAY_DISTANCE_SLOTS' ? { text: `Min. Distance (in ${this.$helpers.getUnitType(this.event.unit)})`, value: 'min_distance', sortable: false } : null,
        this.race.team == 'RELAY_DISTANCE_SLOTS' ? { text: `Goal Distance (in ${this.$helpers.getUnitType(this.event.unit)})`, value: 'distance', sortable: false} : null,
        this.race.team == 'RELAY_DISTANCE_SLOTS' ? { text: `Max. Distance (in ${this.$helpers.getUnitType(this.event.unit)})`, value: 'max_distance', sortable: false} : null,
        this.race.team == 'RELAY_TIME_SLOTS' ? { text: 'Start (included)', value: 'start', sortable: false} : null,
        this.race.team == 'RELAY_TIME_SLOTS' ? { text: 'End (excluded)', value: 'end', sortable: false} : null,
      ].filter(x => x != null);
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.v-data-table.v-data-table--dense td { padding-top: 4px !important; padding-bottom:  4px !important;}
.v-input.v-input--hide-details { margin-top: 0 !important; }
.vue__time-picker input.display-time { width: 0 !important;}
.v-data-table__wrapper { overflow: inherit !important;}
</style>

